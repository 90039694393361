<template>
  <div class="w-100">
     <div class="colon-broom-info"><div class="colon-broom-image-dev"><img data-src="https://images.quizell.com/website/colonbroomlogo.svg" src="https://images.quizell.com/website/colonbroomlogo.svg" class="colon-broom-image ls-is-cached lazyloaded"></div></div>
     <LazyImage class="step-black" src="https://images.quizell.com/colon-broom/step-black.svg" style="position: fixed; bottom: 0px; right: 0px;" ></LazyImage>
   <div class="d-flex d-md-none">
    <div class="col-6 ">
        <div class="">
                <LazyImage class=" w-100" src="https://images.quizell.com/colon-broom/image%20(30).png"  ></LazyImage>
                
            </div>
    </div>
    <div class="col-6 ">
        <div class="">
            <LazyImage class=" w-100" src="https://images.quizell.com/colon-broom/image%20(29).png"  ></LazyImage>
        </div>
    </div>
   </div>
     <div class="d-flex image-margin" style="margin-top:82px">
        <div class="col-12 col-md-4 d-none d-md-block">
            <div class="">
                <LazyImage class=" w-100" src="https://images.quizell.com/colon-broom/image%20(30).png"  ></LazyImage>
                
            </div>
        </div>
        
        <div class="col-12 col-md-4">
            <form @submit.prevent="saveLead()">
            <div class="w-100">
                
                <div class="colon-broom-lead-heading">Enter your email</div>
            </div>
            <div class="">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model="email"  required type="email" placeholder="Enter your email address">
            
        </div>
        <i style="font-size: 14px;
    color: red;" v-show="isSubmit&&!isValidEmail">Please enter valid email</i>
        <div class="d-flex flex-column" style="gap:32px;margin-top:20px">
            <div class="d-flex gap align-items-center">
                <input type="checkbox" id="checkbox" name="" style="height: 16px;
    width: 16px;
    border-radius: 2px;"> <label for="checkbox" style="color: var(--Grey-Base, #9499A2);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; " class="m-0">By checking this box, you agree to get future information from us</label>
            </div>
            <div class="colon-broom-notice">
                <strong>Important!</strong> We don't send spam or share email addresses. We respect your privacy.
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">

<button class="colon-broom-next-button " type="submit">Continue</button>

<div class="colon-broom-tooltip">
    SALE UP TO XX% OFF
</div>
</div>
        </div>
      </div>
    </form>
        </div>
        <div class="col-12 col-md-4 d-none d-md-block">
            <LazyImage class=" w-100" src="https://images.quizell.com/colon-broom/image%20(29).png"  ></LazyImage>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapMutations } from 'vuex';
import axios from 'axios'
export default {
    props:["apiToken"],
computed:{
    ...mapGetters([
      "getSavedLeadID",
      "getSelectedPage",
      "getPreviewSettings",
    ]),
    isValidEmail(){
        return this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    },
    getQuizId(){
        let {quizId} = this.getPreviewSettings
        return quizId
    }
},
    data(){
        return{
            email:'',
            isSubmit:false
        }
    },
    
    methods:{
        ...mapMutations([ "setSavedLeadID"]),
        async saveLead(){
   
   this.isSubmit=true;
   if(!this.isValidEmail)
   return
   this.isLoading=true;
   try{
       const leadData = {...this.getSavedLeadID}
   leadData.email = this.email;
//    leadData.custom_inputs.detail =  this.emailDetail.detail;
       let data = {
        api_token:this.apiToken,
        quizId:this.getQuizId,
         quiz_analytic_id: this.getSavedLeadID.quiz_analytic_id,
       leadData:JSON.stringify(leadData)
   }
   const response = await axios.post(`/api/saveFinalLead`, data);
   if(response.status==200)
   {
    this.setSavedLeadID(response.data.data);
    this.goToNextPreviewPage(this.getSelectedPage.value);
   }
   }
   catch(e){
       this.$toasted.show("Server Error", {
           theme: "toasted-primary",
           type: "error",
           position: "bottom-center",
           duration: 2000,
         });
       throw e
   }
   finally{
       this.isLoading=false;
   }
   
 
}
},
      

}
</script>

<style scoped>
.colon-broom-tooltip{
    position: relative;
    margin-top: 16px;
    padding: 12px 40px;
    background: rgb(33, 37, 41);
    color: #FFF;
text-align: center;
font-feature-settings: 'liga' off, 'clig' off;
width: 306px;
/* Mobile/Body 2 (Bold) */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 133.333% */
border-radius: 8px;
}
.colon-broom-tooltip::before{
    content: '';
    position: absolute;
    top: 0px;
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 12px;
    height: 12px;
    background: rgb(33, 37, 41);
}
.colon-broom-notice{
    color: var(--Grey-Black, #000);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: italic;
font-weight: 700;
line-height: 24px; /* 150% */
}
.colon-broom-info {
    max-width: 1200px;
    padding: 24px 24px 32px;
    margin: 0px auto;
}

@media (min-width: 768px) {
    .colon-broom-info {
        padding: 34px 24px 56px;
    }
}

.colon-broom-info {
    display: flex;
    align-items: center;
    gap: 20px;
}

.colon-broom-info {
    max-width: 1200px;
    padding: 24px 24px 32px;
    margin: 0px auto;
}

@media (min-width: 768px) {
    .colon-broom-info {
        padding: 34px 24px 56px;
    }
}

.colon-broom-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
}
@media (max-width: 639px) {
    .step-black {
        width: 43px;
        height: 64px;
    }
    .colon-broom-next-button{
        width: 100%!important;
    }
    .colon-broom-tooltip{
        width: 100%!important;
    }
    .image-margin{
        margin-top: 40px!important;
    }
    .colon-broom-lead-heading{
        font-size: 24px!important;
    }
}
.colon-broom-lead-heading{
                    color: var(--Grey-Black, #000);
text-align: center;

font-family: Recoleta  "Poppins";
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 40px; 
padding-bottom:35px;
                }
                .colon-broom-input-control{
    border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    color: rgb(0, 0, 0);
    
}
.colon-broom-input-control:focus-within{
    border-bottom: 1px solid rgb(34, 191, 142);
}
.colon-broom-input-control input{
    border:none;
    outline: none;
    flex: 1;
}
.colon-broom-next-button {
  padding: 18px 72px;
  border-radius: 40px;
  background: var(--Green-Base, #22BF8E);
  color: var(--Grey-White, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  width: 350px;
  line-height: 12px; /* 100% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
}
.colon-broom-next-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -200%;
  height: 200%;
  width: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.3) 25%, transparent 50%, rgba(255, 255, 255, 0.3) 75%);
  transition: left 0.5s;
  z-index: 1;
  transform: rotate(45deg);
}

.colon-broom-next-button:hover::before {
  width: 200%;
  left: 100%;
}

.colon-broom-next-button span {
  position: relative;
  z-index: 2;
 

}
input:valid ~ .colon-broom-input-control {
            border-bottom: 2px solid green; /* This won't work directly, shown for concept */
        }

        input:invalid ~ .colon-broom-input-control {
            border-bottom: 2px solid red; /* This won't work directly, shown for concept */
        }
        
    
</style>